<style>
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
}
</style>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import btnComponent from "@/components/btnComponent";

// import moment from "moment";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
/**
 * Advanced-table component
 */
export default {
  page: {
    title: "ตารางการนัดหมายลูกค้า",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    btnComponent,
  },
  data() {
    return {
      title: "ตารางการนัดหมาย",
      items: [
        {
          text: "การนัดหมาย",
        },
        {
          text: "ตารางการนัดหมายลูกค้า",
          active: true,
        },
      ],
      optionsShow: [
        {
          showId: "ALL",
          shownameTh: "แสดงทั้งหมด",
        },
        {
          showId: "CRM",
          shownameTh: "แสดงเฉพาะนัดหมาย",
        },
        {
          showId: "VM",
          shownameTh: "แสดงเฉพาะการซ่อม",
        },
      ],
      rowDataSd: [],

      loading: undefined,
      showVm: true,
      showAp: true,
      form: {},
      linkPtintFlowslotAppt: "",
      linkPtintFlowslot: "",
      // formcontact Zone
      formContactButtonDisabled: true,
      formContact: false,
      TimeCTEnd: "",
      TimeCTStart: "",
      subjectCT: "",
      cusIdCT: "",
      cusNameCT: "",
      VehiclesCT: "",
      messageCT: "",
      contactNote: "",
      optionsResult: [],
      optionsContactType: [
        // {
        //   contactTypeId: "CALL",
        //   contactTypeNameTh: "โทร",
        // },
        {
          contactTypeId: "SMS",
          contactTypeNameTh: "SMS",
        },
        // {
        //   contactTypeId: "EMAIL",
        //   contactTypeNameTh: "อีเมล",
        // },
        {
          contactTypeId: "MANUAL",
          contactTypeNameTh: "บันทึกข้อความ (Manual)",
        },
      ],
      contactType: [],
      resultId: "",
      module: "",
      apptIdCT: "",
      rowsUserCT: [],
      rowsDataServiceCT: [],
      newCusNameCT: "",
      newVehicleCT: "",
      statusCT: [],
      cusMobilePhoneCT: "",
      // ends form Contact

      // PostAP Form
      newCusName: "",
      newVehicle: "",
      branchId: "",
      selectedType: [],
      rowsUser: [],
      rowsService: [],
      rowsDataService: [],
      rowsMasterSD: [],
      rowsApptype: [],
      rowsFslot: [],
      rowsPslot: [],
      licensePlate: "",
      vin: "",
      cusName: "",
      apptDate: "",
      submitform: false,
      status: "",
      apptTime: "",
      apptTypeRemark: "",
      remark: "",
      cusMobilePhone: "",
      apptTypeId: "",
      pslotId: "",
      fslotId: "",
      distances: "",
      optionsAppointment: [
        {
          text: "นัดหมาย",
          status: "APPT",
        },
        {
          text: "ยืนยันการนัดหมาย",
          status: "CONF",
        },
      ],
      // end PostAPform

      // Get AP Params
      itemApG: "",
      rowShowAp: "",
      rowShowContacts: "",
      apptCode: "",

      // Ends Get AP Params

      //getVm Params
      // update status
      optionsStatusFslot: [
        {
          repairStatusID: "COUT",
          nameTh: "Clock OUT",
        },
        {
          repairStatusID: "SW",
          nameTh: "เริ่มล้าง",
        },
        {
          repairStatusID: "FW",
          nameTh: "ล้างเสร็จ",
        },
      ],
      repairStatus: [],
      itemVMP: "",
      svIdP: "",
      repairIdP: "",
      // end update status
      // End Vm params
      branchIdLC: "",
      DateSearch: "",
      selectShow: "",
      rowSlot: [],
      rowSlot2: [],
      SlotNum: [],
      countRowSlot: "",
      localDataBranch: [], //เป็น-array Id
      localData: [],
      rowAp: [],

      colSp: 4,
      fslot1: false,

      Col1: false,
      
    };
  },
  validations: {
    form: {
      
      fname: {
        required,
      },
      lname: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      zipcode: {
        required,
      },
    },
    distances: {
        
      },
    vin: {
      required,
    },
    cusName: {
      required,
    },

    range: {
      minlen: {
        required,
        minLength: minLength(6),
      },
      maxlength: {
        required,
        maxLength: maxLength(6),
      },
      between: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(10),
      },
      minval: {
        required,
        minValue: minValue(6),
      },
      maxval: {
        required,
        maxValue: maxValue(6),
      },
      rangeval: {
        required,
        minValue: minValue(6),
        maxValue: maxValue(100),
      },
      expr: {
        required,
      },
    },
    typeform: {
      name: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
      email: {
        required,
        email,
      },
      url: {
        required,
        url,
      },
      digit: {
        required,
        numeric,
      },
      number: {
        required,
        numeric,
      },
      alphanum: {
        required,
        alphaNum,
      },
      textarea: {
        required,
      },
    },
  },

  computed: {},

  created() {
    this.getDataAppoinType();
    this.getLocalData();
    this.getNow();
    this.getDataResult();
    this.getDataPslot();
    this.getDataFslot();
    this.getDataSd();
  },
  // mounted(){
  //   $(this.$refs.modalShowAp).on("hidden.bs.modal", this.clearsubmitAp);
  // },

  methods: {
    getDataSd: function () {
      this.loading = true;
      useNetw
        .get("api/master/service-distances", {}) // เอามาจากไหน

        .then((response) => {
          this.rowDataSd = response.data.data;
          // // console.log("SD = ", this.rowDataSd);
        })
        .catch((err) => {
          //ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    changeStatusFL(statusVm) {
      if (statusVm == "SW") {
        return "กำลังล้าง";
      } else if (statusVm == "CIN") {
        return "เข้าจอด";
      } else if (statusVm == "COUT") {
        return "เสร็จสิ้น";
      } else if (statusVm == "FW") {
        return "ล้างเสร็จ";
      }
    },
    changeStatusAP(statusAp) {
      if (statusAp == "CONF") {
        return "ยืนยันการนัดหมาย";
      } else if (statusAp == "APPT") {
        return "นัดหมาย";
      } else {
        return "ยกเลิกการนัดหมาย";
      }
    },
    getDataResult() {
      useNetw
        .get("api/master/contact-results", {})
        .then((response) => {
          this.optionsResult = response.data.data;
          // // console.log("optionnResult", this.optionsResult);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },

    colorChangeClass(itemVM) {
      return {
        "bg-danger text-center text-white rounded ": itemVM == "CIN",
        "bg-primary text-center text-white rounded ": itemVM == "SW",
        "bg-success text-center text-white rounded ": itemVM == "FW",
        "bg-secondary text-center text-white rounded": itemVM == "COUT",
      };
    },
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.cusName.required != true || this.$v.vin.required != true) {
        this.$v.$touch();
      } else {
        this.submitAppointment();
      }
    },
    tooltipFormPut() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.cusName.required != true || this.$v.vin.required != true) {
        this.$v.$touch();
      } else {
        // // console.log("Put Success");
      }
    },

    customLabel({ nameTh, familyNameTh }) {
      return `${nameTh}  ${familyNameTh}`;
    },

    getDataAppoinType: function () {
      useNetw
        .get("api/master/appointment-types", {})
        .then((response) => {
          this.rowsApptype = response.data.data;
          // // console.log("rowsApptype", response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    setDataCus() {
      // // console.log(this.vin);
      if (this.vin.ownerId != "" || this.vin.driverId != "") {
        this.showDataCustomer();
        this.newVehicle = this.vin.licensePlate;
      } else {
        this.getDataCustomer();
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowUser = filteredItems.length;
      // // console.log(this.rowUser);
    },
    getDataFslot: function () {
      useNetw
        .get("api/master/flow-slots", {
          params: {
            branchId: this.branchIdLC,
          },
        })
        .then((response) => {
          this.rowsFslot = response.data.data;
          // // console.log("fslot", response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataPslot: function () {
      useNetw
        .get("api/master/gs-parking-slots", {
          params: {
            branchId: this.branchIdLC,
          },
        })
        .then((response) => {
          this.rowsPslot = response.data.data;
          // // console.log("pslot", response);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataVehicle: function () {
      this.isLoading = true;
      useNetw
        .get("api/vehicle", {
          params: {
            page: 1,
            perPage: 100,
            // vin: this.vin.vin,
            branchId: [this.branchIdLC],
          },
        })
        .then((response) => {
          this.rowsDataService = response.data.data;
          this.cusName.cusId = response.data.ownerId;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    getDataCustomer: function () {
      // this.overlayFlag = true;

      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.branchIdLC],
            nameTh: this.cusName.nameTh,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
          // // console.log("cusData", this.rowsUser);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },

    showDataCustomer() {
      useNetw
        .get("api/customer/show", {
          params: {
            cusId: this.vin.ownerId,
          },
        })
        .then((response) => {
          this.rowUserG = response.data.data;
          // // console.log("centralCus G ", this.rowUserG);

          this.cusName = {
            cusId: response.data.data.cusId,
            nameTh: response.data.data.nameTh,
            familyNameTh: response.data.data.familyNameTh,
            customLabel: this.customLabel(
              response.data.data.nameTh,
              response.data.data.familyNameTh
            ),
          };
          // this.nameTh = ;
          // this.familyNameTh = ;
          this.newCusName = response.data.data.nameTh;
          this.cusMobilePhone = response.data.data.mobilePhone;

          // // console.log("ResCus ======", response.data.data);
        })
        .catch(() => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify("ไม่พบข้อมูลลูกค้าที่ตรงกับหมายเลขทะเบียน"),
            appConfig.swal.type.error
          );
          this.cusName = [];
          this.newCusName = "";
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getRemoveSelected: function () {
      this.isLoading = true;
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: [this.branchId],
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchIdLC = user.branchId;
      const localDataBranch = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item.branchId);
      });

      this.localDataBranch = localDataBranch;
    },

    getAppointment: function () {
      this.loading = true;
      useNetw
        .get("api/appointment", {
          params: {
            branchId: [this.branchId.branchId],
            page: 1,
            perPage: 100,
            apptDate: this.DateSearch,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowAp = response.data.data;
          // // console.log("Appointments ====== > ", this.rowAp);
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },

    getDataFlowSlot: function () {
      this.loading = true;
      useNetw
        .get("api/flow-slot", {
          params: {
            branchId: this.branchId.branchId,
            date: this.DateSearch,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowSlot = response.data.data;
          // // console.log("flow-Slot ====== > ", this.rowSlot);
          this.getDatParking();
          this.getAppointment();
          // // console.log(JSON.stringify(this.DateSearch));
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getDatParking: function () {
      this.loading = true;
      useNetw
        .get("api/master/gs-parking-slots", {
          params: {
            branchId: this.branchId.branchId,
            date: this.DateSearch,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          // this.rowSlot2 = response.data.data;
          this.SlotNum = response.data.data;
          // console.log"itemSlotNum (Parking) ====== > ", this.SlotNum);
          // this.setFlowSlot();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    popUpmodalPostAp(fslot, itemSlotNum) {
      this.fslotId = fslot;
      this.pslotId = itemSlotNum;
      this.apptDate = this.DateSearch;
      // // console.log("fslotP", this.fslotId);
      // // console.log("pslotP", this.pslotId);
      // // console.log("dateP", this.apptDate);
      this.$refs["modalPostAp"].show();
    },
    BackmodalPostAp() {
      this.$refs["modalPostAp"].hide();
    },
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1 > 9 ? "" : "0") +
        (today.getMonth() + 1) +
        "-" +
        (today.getDate() > 9 ? "" : "0") +
        today.getDate();
      // today.getDate();

      this.DateSearch = date;
      // // console.log(this.DateSearch);
      // // console.log(this.DateSearch);
    },
    selectแสดงผล() {
      if (this.selectShow.showId === "ALL") {
        this.showVm = true;
        this.showAp = true;
      } else if (this.selectShow.showId === "CRM") {
        this.showAp = true;
        this.showVm = false;
      } else {
        this.showVm = true;
        this.showAp = false;
      }
    },

    submitAppointment: function () {
      // // console.log('parseInt ==> ', parseInt(this.fslotId, 10));
      // // console.log('normal ==> ', this.fslotId);
      useNetw
        .post("/api/appointment/store", {
          branchId: this.branchIdLC,
          vehicleId: this.vin.vehicleId,
          cusId: this.cusName.cusId,
          status: this.status.status,
          apptDate: this.apptDate,
          apptTime: this.apptTime,
          apptTypeRemark: this.apptTypeRemark,
          remark: this.apptTypeRemark,
          cusName: this.cusName.nameTh,
          cusMobilePhone: this.cusMobilePhone,
          vehiclePlate: this.vin.licensePlate,
          apptTypeId: this.selectedType,
          pslotId: this.pslotId,
          fslotId: parseInt(this.fslotId, 10),
          distanceId: this.distances.distanceId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            //// console.log(response),
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataFlowSlot();
          this.itemApG = response.data.apptId;
          // // console.log('APPTID ====> ',this.itemApG);
          // this.BackmodalPostAp();
          // this.clearsubmitAp();
          // this.formContact = true;

          this.formContactButtonDisabled = false;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    clearsubmitAp() {
      this.cusName = [];
      this.vin = [];
      this.status = [];
      this.cusMobilePhone = "";
      this.apptTime = "";
      this.selectedType = [];
      this.apptTypeRemark = "";
      this.newCusName = "";
      this.newVehicle = "";
      this.itemApG = "";
      this.rowShowContacts = [];
      this.distances = [];

      // this.getDataCustomer();
      this.getDataVehicle();
      this.getDataAppoinType();
    },

    showModalApData(itemAp) {
      this.itemApG = itemAp;
      // // console.log("apptId :", this.itemApG);
      this.showAPdata();
      this.getContactsApData();
      this.$refs["modalShowAp"].show();
    },
    //show CRM Function
    showAPdata: function () {
      useNetw
        .get("api/appointment/show", {
          params: {
            apptId: this.itemApG,
          },
        })
        .then((response) => {
          // console.log(response.data.branchAppointment);
          this.itemApG = response.data.branchAppointment.apptId;
          this.rowsShowDetailAppointment = response.data.branchAppointment;
          this.cusName = {
            cusId: response.data.branchAppointment.cusId,
            nameTh: response.data.branchAppointment.fullCustomerName,
          };
          this.rowsShowDetailAppointment.apptTypeRemark =
            response.data.branchAppointment.appt_type_remark;
          this.apptDate = response.data.branchAppointment.apptDate;
          this.apptTime = response.data.branchAppointment.apptTime;
          this.cusMobilePhone = response.data.branchAppointment.cusMobilePhone;
          this.vin = {
            vehicleId: response.data.branchAppointment.vehicleId,
            licensePlate: response.data.branchAppointment.vehiclePlate,
            vin: response.data.branchAppointment.vin,
          };
          this.selectedTypeX = response.data.branchAppointmentHasType;
          this.selectedTypeX.forEach((element) => {
            this.selectedType.push(element.apptTypeId);
          });

          this.status = {
            status: response.data.branchAppointment.status,
            text: response.data.branchAppointment.status,
          };
          if (this.status.text === "APPT") {
            this.status.text = "นัดหมาย";
          } else {
            this.status.text = "ยืนยันนัดหมาย";
          }
          this.apptTypeRemark =
            response.data.branchAppointment.appt_type_remark;
          this.newCusName = response.data.branchAppointment.cusName;
          this.newVehicle = response.data.branchAppointment.vehiclePlate;
          this.apptTypeRemark = response.data.branchAppointment.remark;

          this.pslotId = {
            pslotId: response.data.branchAppointment.pslotId,
            nameTh: "ช่องจอด " + (response.data.branchAppointment.pslotId - 1),
          };
          this.fslotId = {
            fslotId: response.data.branchAppointment.fslotId,
            nameTh: "Slot " + response.data.branchAppointment.fslotId,
          };
          this.distances= {
            distanceId : response.data.branchAppointment.distanceId,
            nameTh : response.data.branchAppointment.distanceNameTh
          }
          // // console.log(
          //   "rowsShowDetailAppointment",
          //   this.rowsShowDetailAppointment
          // );
          // // console.log("Data =", response.data);

          const event2 = new Date(`12/12/2564 ${this.apptTime}`);
          this.apptTime =
            ("0" + event2.getHours()).slice(-2) +
            ":" +
            ("0" + event2.getMinutes()).slice(-2);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getContactsApData: function () {
      useNetw
        .get("api/appointment/contacts", {
          params: {
            apptId: this.itemApG,
          },
        }) // ?เอามาจากไหน

        .then((response) => {
          this.rowShowContacts = response.data.data;
          // // console.log("showAPContact : ", this.rowShowContacts);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    popupModalUpdateFslot(itemVM) {
      // // console.log("VM", itemVM);
      this.itemVMP = itemVM;
      this.svIdP = itemVM.svId;
      this.repairIdP = itemVM.repairId;

      // // console.log("dataVMX = ", this.itemVMP);
      // // console.log("sv = ", this.svIdP);
      // // console.log("rp = ", this.repairIdP);

      this.$refs["modalUpdateFslot"].show();
      // this.repairStatus = this.itemVMP.repairStatus
    },
    submitUpdateAppointment: function () {
      useNetw
        .put("/api/appointment/update", {
          apptId: this.itemApG,
          branchId: this.branchIdLC,
          vehicleId: this.vin.vehicleId,
          cusId: this.cusName.cusId,
          status: this.status.status,
          apptDate: this.apptDate != null ? this.apptDate : "",
          apptTime: this.apptTime != null ? this.apptTime : "",
          apptTypeRemark:
            this.apptTypeRemark != null ? this.apptTypeRemark : "",
          remark: this.remark != null ? this.apptTypeRemark : "",
          cusName: this.cusName.nameTh,
          cusMobilePhone:
            this.cusMobilePhone != null ? this.cusMobilePhone : "",
          vehiclePlate: this.vin.licensePlate,
          apptTypeId: this.selectedType,
          pslotId: this.pslotId.pslotId != null ? this.pslotId.pslotId : "",
          fslotId: this.fslotId.fslotId != null ? this.fslotId.fslotId : "",
          distanceId: this.distances.distanceId,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            //// console.log(response),
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalShowAp"].hide();
          this.clearsubmitAp();
          this.getAppointment();
          // this.$router.push({ name: "appointment" });
        })
        .catch((err) => {
          // // console.log(err.response.data.message)
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.validationMessage),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    closemodalUpdateFslot() {
      this.$refs["modalUpdateFslot"].hide();
      this.repairStatus = "";
    },
    putStatusVm: function () {
      this.loading = true;
      useNetw
        .put("api/service/repair/update", {
          svId: this.svIdP,
          repairId: this.repairIdP,
          status: this.repairStatus.repairStatusID,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.closemodalUpdateFslot();
          this.getDataFlowSlot();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    closemodalPutAp() {
      this.clearsubmitAp();
      this.$refs["modalShowAp"].hide();
    },

    postContact: function () {
      // const timeST = this.DateSearch + " " + this.TimeCTStart + ":00";
      // const timeEnd = this.DateSearch + " " + this.TimeCTEnd + ":00";
      // // console.log(timeST, "=>", timeEnd);
      const ContactPost = new FormData();
      ContactPost.append("branchId", this.branchIdLC);
      ContactPost.append("cusId", this.cusName.cusId);
      ContactPost.append("vehicleId", this.vin.vehicleId);
      ContactPost.append("customer", this.newCusName);
      ContactPost.append("contactInfo", this.cusMobilePhone);
      // ContactPost.append("startTime", timeST);
      // ContactPost.append("finishTime", timeEnd);
      // ContactPost.append(
      //   "duration",
      //   moment
      //     .utc(
      //       moment(this.TimeCTEnd, "HH:mm").diff(
      //         moment(this.TimeCTStart, "HH:mm")
      //       )
      //     )
      //     .unix()
      // );
      // moment.utc(moment(this.TimeCTStart,"HH:mm").diff(moment(this.TimeCTEnd,"HH:mm"))).format("HH:mm")
      // // console.log('duration' ,this.TimeCTStart.diff(this.TimeCTEnd));
      ContactPost.append("subject", this.subjectCT);
      ContactPost.append("message", this.messageCT);
      ContactPost.append("contactNote", this.contactType.contactTypeNameTh);
      ContactPost.append("contactType", this.contactType.contactTypeId);
      ContactPost.append("resultId", this.resultId.resultId);
      ContactPost.append("module", "APPT");
      ContactPost.append("apptId", this.itemApG);

      useNetw
        .post("api/contact/store", ContactPost)
        .then((response) => {
          // this.cusIdPost = response.data.cusId;
          // // console.log("Success ! ");
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.formContact = false;
          this.getContactsApData();
          this.subjectCT = "";
          this.messageCT = "";
          this.resultId = [];
          this.contactType = [];
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getReportFlowSlot() {
      this.loading = true;
      useNetw
        .get("api/flow-slot/print-flow-slot", {
          params: {
            branchId: this.branchId.branchId,
            date: this.DateSearch,
          },
        }) // ?เอามาจากไหน
        .then((data) => {
          // // console.log(data.data.url);
          this.linkPtintFlowslot = data.data.url;
          window.open(data.data.url);
          // this.$refs["modalPrintflowSlot"].show();
          // window.open = data.data.url;
          // this.loading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("ไม่พบข้อมูลรถในวันที่ " + this.DateSearch),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },

    getReportFlowSlotAppointmemt() {
      this.loading = true;
      useNetw
        .get("api/appointment/print-flow-slot", {
          params: {
            branchId: this.branchId.branchId,
            apptDate: this.DateSearch,
          },
        }) // ?เอามาจากไหน
        .then((data) => {
          // // console.log(data.data.url);
          this.linkPtintFlowslotAppt = data.data.url;
          // window.open(data.data.url, '_blank');
          window.location = (data.data.url);
          // this.$refs["modalPrintflowSlot"].show();
          // window.open = data.data.url;
          // this.loading = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify("กรุณาเลือกสาขา"),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(
                "ไม่พบข้อมูลการนัดหมายในวันที่ " + this.DateSearch
              ),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton-table
                  :rows="5"
                  :columns="6"
                  :table-props="{ bordered: false, striped: true }"
                  animation="throb"
                ></b-skeleton-table>
              </template>
              <div class="row">
                <div class="col-md-10">
                  
                </div>
                <div class="col-md-2 text-end">
                  <!-- <button class="btn btn-primary" @click="popUpmodalPostAp">สร้างการนัดหมาย</button> -->
                  <router-link :to="{ name: 'home' }">
                    <a class="btn btn-secondary">กลับสู่หน้าหลัก</a>
                  </router-link>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-5">
                  <div class="mb-3 position-relative">
                    <code> * </code>
                    <label for="branchId">สาขา</label><br />
                    <multiselect
                      v-model="branchId"
                      :options="localData"
                      label="nameTh"
                    >
                    </multiselect>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="mb-3 position-relative">
                    <code> * </code>
                    <label for="birthDate">วันที่</label>

                    <date-picker
                      v-model="DateSearch"
                      :first-day-of-week="1"
                      format="YYYY-MM-DD"
                      value-type="format"
                      lang="en"
                    >
                    </date-picker>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3 position-relative">
                    <label for="branchId">การแสดงผล</label><br />
                    <multiselect
                      v-model="selectShow"
                      :options="optionsShow"
                      label="shownameTh"
                      @input="selectแสดงผล()"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>

              <div class="row text-end">
                <div class="col-md-auto"></div>
                <div class="col-md-12">
                  <label for="Search"><br /></label>

                  <label for="branchId"
                    ><code>
                      *หมายเหตุ หากต้องการพิมพ์เอกสาร กรุณาเลือกสาขา
                      และวันที่</code
                    ></label
                  ><br />
                  <btnComponent @click="getDataFlowSlot()" changeStyle="search">
                  </btnComponent>
                  &nbsp;
                  <button class="btn btn-primary" @click="getReportFlowSlot()">
                    <i class="uil-print"></i>
                    <!-- @click="getReportFlowSlot()" -->
                    พิมพ์รายงานการเข้าซ่อม
                  </button>
                  &nbsp;
                  <button
                    class="btn btn-primary"
                    @click="getReportFlowSlotAppointmemt()"
                  >
                    <i class="uil-print"></i>
                    <!-- @click="getReportFlowSlot()" -->
                    พิมพ์รายงานการนัดหมาย
                  </button>
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-md-6 text-center">
                  <center>
                    <div class="avatar-sm bg-success rounded"></div>
                  </center>
                  <b>Normal flowslot</b>
                </div>
                <div class="col-md-6 text-center">
                  <center>
                    <div class="avatar-sm bg-warning rounded"></div>
                  </center>
                  <b>CRM flowslot</b>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col">
                  <!-- <h3><code> ** กดที่ช่องว่างเพื่อสร้างการนัด</code></h3> -->
                </div>
              </div>
              <div class="table-responsive">
                <table class="table mb-0 table-bordered">
                  <thead>
                    <tr>
                      <th rowspan="8" class="text-center">ช่องบริการ / เวลา</th>
                      <th colspan="8" class="text-center">SlotTime 1</th>
                      <th colspan="8" class="text-center">SlotTime 2</th>
                      <th colspan="4" class="text-center">-</th>
                      <th colspan="8" class="text-center">Slot Time 3</th>
                      <th colspan="12" class="text-center">SlotTime 4</th>
                    </tr>
                    <tr>
                      <th :colspan="colSp" class="text-center">08.00</th>
                      <th :colspan="colSp" class="text-center">09.00</th>
                      <th :colspan="colSp" class="text-center">10.00</th>
                      <th :colspan="colSp" class="text-center">11.00</th>
                      <th :colspan="colSp" class="text-center">12.00</th>
                      <th :colspan="colSp" class="text-center">13.00</th>
                      <th :colspan="colSp" class="text-center">14.00</th>
                      <th :colspan="colSp" class="text-center">15.00</th>
                      <th :colspan="colSp" class="text-center">16.00</th>
                      <!-- <th :colspan="colSp" class="text-center">17.00</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(itemSlotNum, index) in SlotNum" :key="index">
                      <th class="text-center">{{ itemSlotNum.nameTh }}</th>

                      <td colspan="8">
                        <div
                          v-for="(itemFL, index) in rowSlot"
                          :key="'FL' + index"
                        >
                          <div
                            v-if="
                              itemFL.pslotId === itemSlotNum.pslotId &&
                              itemFL.vehicles.length > 0
                            "
                          >
                            <div
                              v-for="(itemVM, index) in itemFL.vehicles"
                              :key="'VM' + index"
                            >
                              <div
                                v-show="showVm"
                                v-if="itemVM.fslotId === 1"
                                v-bind:class="
                                  colorChangeClass(itemVM.repairStatus)
                                "
                                @click="popupModalUpdateFslot(itemVM)"
                              >
                                {{ itemVM.licensePlate }} <br />
                                (ช่าง : {{ itemVM.mechName }}) <br />
                                ชื่อลูกค้า : คุณ{{ itemVM.customerNameTh }}
                                <br />
                                สถานะ :
                                {{ changeStatusFL(itemVM.repairStatus) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- //Ap Flowslot -->

                        <div
                          class="bg-warning text-white rounded"
                          v-for="(itemAp, index) in rowAp"
                          :key="'AP' + index"
                        >
                          <div
                            v-show="showAp"
                            v-if="
                              itemAp.fslotId === 1 &&
                              itemAp.pslotId === itemSlotNum.pslotId
                            "
                            class="text-center"
                            @click="showModalApData(itemAp.apptId)"
                          >
                            {{ itemAp.vehiclePlate }} <br />
                            ( ลูกค้า : คุณ{{ itemAp.customerName }} ) <br />
                            โทร : {{ itemAp.customerMobilePhone }} <br />
                            <b></b> สถานะ : {{ changeStatusAP(itemAp.status)
                            }}<br />
                          </div>
                        </div>
                        <div
                          v-if="
                            rowAp.filter(
                              (itemAp) =>
                                itemAp.fslotId === 1 &&
                                itemAp.pslotId === itemSlotNum.pslotId
                            ).length < 1
                          "
                        >
                          <!-- <a  class="mx-auto mb-auto">เพิ่มการนัดหมาย</a> -->
                          <b-button
                            size="sm"
                            variant="outline-secondary"
                            class="mb-2 w-100"
                            @click="popUpmodalPostAp('1', itemSlotNum.pslotId)"
                          >
                            เพิ่มการนัดหมาย
                          </b-button>
                        </div>

                        <!-- Ends Ap flowslot -->
                      </td>

                      <td colspan="8">
                        <div
                          v-for="(itemFL, index) in rowSlot"
                          :key="'FL' + index"
                        >
                          <div
                            v-if="
                              itemFL.pslotId === itemSlotNum.pslotId &&
                              itemFL.vehicles.length > 0
                            "
                          >
                            <div
                              v-for="(itemVM, index) in itemFL.vehicles"
                              :key="'VM' + index"
                            >
                              <div
                                v-show="showVm"
                                v-if="itemVM.fslotId === 2"
                                v-bind:class="
                                  colorChangeClass(itemVM.repairStatus)
                                "
                                @click="popupModalUpdateFslot(itemVM)"
                              >
                                {{ itemVM.licensePlate }} <br />
                                (ช่าง : {{ itemVM.mechName }}) <br />
                                ชื่อลูกค้า : คุณ{{ itemVM.customerNameTh }}
                                <br />
                                สถานะ :
                                {{ changeStatusFL(itemVM.repairStatus) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            rowAp.filter(
                              (itemAp) =>
                                itemAp.fslotId === 2 &&
                                itemAp.pslotId === itemSlotNum.pslotId
                            ).length < 1
                          "
                        >
                          <!-- <a  class="mx-auto mb-auto">เพิ่มการนัดหมาย</a> -->
                          <b-button
                            size="sm"
                            variant="outline-secondary"
                            class="mb-2 w-100"
                            @click="popUpmodalPostAp('2', itemSlotNum.pslotId)"
                          >
                            เพิ่มการนัดหมาย
                          </b-button>
                        </div>
                        <!-- //Ap Flowslot -->

                        <div
                          class="bg-warning text-white rounded"
                          v-for="(itemAp, index) in rowAp"
                          :key="'AP' + index"
                        >
                          <div
                            v-show="showAp"
                            v-if="
                              itemAp.fslotId === 2 &&
                              itemAp.pslotId === itemSlotNum.pslotId
                            "
                            class="text-center"
                            @click="showModalApData(itemAp.apptId)"
                          >
                            {{ itemAp.vehiclePlate }} <br />
                            ( ลูกค้า : คุณ{{ itemAp.customerName }} ) <br />
                            โทร : {{ itemAp.customerMobilePhone }} <br />
                            สถานะ : {{ changeStatusAP(itemAp.status) }} <br />
                          </div>
                        </div>

                        <!-- Ends Ap flowslot -->
                      </td>

                      <td colspan="4" class="text-center">-</td>

                      <td colspan="8">
                        <div
                          v-for="(itemFL, index) in rowSlot"
                          :key="'FL' + index"
                        >
                          <div
                            v-if="
                              itemFL.pslotId === itemSlotNum.pslotId &&
                              itemFL.vehicles.length > 0
                            "
                          >
                            <div
                              v-for="(itemVM, index) in itemFL.vehicles"
                              :key="'VM' + index"
                            >
                              <div
                                v-show="showVm"
                                v-if="itemVM.fslotId === 3"
                                v-bind:class="
                                  colorChangeClass(itemVM.repairStatus)
                                "
                                @click="popupModalUpdateFslot(itemVM)"
                              >
                                {{ itemVM.licensePlate }} <br />
                                (ช่าง : {{ itemVM.mechName }}) <br />
                                ชื่อลูกค้า : คุณ{{ itemVM.customerNameTh }}
                                <br />
                                สถานะ :
                                {{ changeStatusFL(itemVM.repairStatus) }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- //Ap Flowslot -->

                        <div
                          class="bg-warning text-white rounded"
                          v-for="(itemAp, index) in rowAp"
                          :key="'AP' + index"
                        >
                          <div
                            v-show="showAp"
                            v-if="
                              itemAp.fslotId === 3 &&
                              itemAp.pslotId === itemSlotNum.pslotId
                            "
                            class="text-center"
                            @click="showModalApData(itemAp.apptId)"
                          >
                            {{ itemAp.vehiclePlate }} <br />
                            ( ลูกค้า : คุณ{{ itemAp.customerName }} ) <br />
                            โทร : {{ itemAp.customerMobilePhone }} <br />
                            สถานะ : {{ changeStatusAP(itemAp.status) }} <br />
                          </div>
                        </div>

                        <!-- Ends Ap flowslot -->
                        <div
                          v-if="
                            rowAp.filter(
                              (itemAp) =>
                                itemAp.fslotId === 3 &&
                                itemAp.pslotId === itemSlotNum.pslotId
                            ).length < 1
                          "
                        >
                          <!-- <a  class="mx-auto mb-auto">เพิ่มการนัดหมาย</a> -->
                          <b-button
                            size="sm"
                            variant="outline-secondary"
                            class="mb-2 w-100"
                            @click="popUpmodalPostAp('3', itemSlotNum.pslotId)"
                          >
                            เพิ่มการนัดหมาย
                          </b-button>
                        </div>
                      </td>

                      <td colspan="8">
                        <div
                          v-for="(itemFL, index) in rowSlot"
                          :key="'FL' + index"
                        >
                          <div
                            class="bg-success text-white rounded"
                            v-if="
                              itemFL.pslotId === itemSlotNum.pslotId &&
                              itemFL.vehicles.length > 0
                            "
                          >
                            <div
                              v-for="(itemVM, index) in itemFL.vehicles"
                              :key="'VM' + index"
                            >
                              <div
                                v-show="showVm"
                                v-if="itemVM.fslotId === 4"
                                v-bind:class="
                                  colorChangeClass(itemVM.repairStatus)
                                "
                                @click="popupModalUpdateFslot(itemVM)"
                              >
                                {{ itemVM.licensePlate }} <br />
                                (ช่าง : {{ itemVM.mechName }}) <br />
                                ชื่อลูกค้า : คุณ{{ itemVM.customerNameTh }}
                                <br />
                                สถานะ :
                                {{ changeStatusFL(itemVM.repairStatus) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- //Ap Flowslot -->

                        <div
                          class="bg-warning text-white rounded"
                          v-for="(itemAp, index) in rowAp"
                          :key="'AP' + index"
                        >
                          <div
                            v-show="showAp"
                            v-if="
                              itemAp.fslotId === 4 &&
                              itemAp.pslotId === itemSlotNum.pslotId
                            "
                            class="text-center"
                            @click="showModalApData(itemAp.apptId)"
                          >
                            {{ itemAp.vehiclePlate }} <br />
                            ( ลูกค้า : คุณ{{ itemAp.customerName }} ) <br />
                            โทร : {{ itemAp.customerMobilePhone }} <br />
                            สถานะ : {{ changeStatusAP(itemAp.status) }} <br />
                          </div>
                        </div>

                        <!-- Ends Ap flowslot -->
                        <div
                          v-if="
                            rowAp.filter(
                              (itemAp) =>
                                itemAp.fslotId === 4 &&
                                itemAp.pslotId === itemSlotNum.pslotId
                            ).length < 1
                          "
                        >
                          <!-- <a  class="mx-auto mb-auto">เพิ่มการนัดหมาย</a> -->
                          <b-button
                            size="sm"
                            variant="outline-secondary"
                            class="mb-2 w-100"
                            @click="popUpmodalPostAp('4', itemSlotNum.pslotId)"
                          >
                            เพิ่มการนัดหมาย
                          </b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Add Ap -->

    <b-modal
      modal-class="modal-fullscreen"
      size="xl"
      ref="modalPostAp"
      hide-footer
      title="เพิ่มการนัดหมาย"
      @hide="clearsubmitAp"
    >
      <div class="row">
        <div class="col-6 col-sm-4 col-md-3">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">ทะเบียน</label>
            <multiselect
              v-model="vin"
              label="licensePlate"
              type="search"
              :options="rowsDataService"
              :show-labels="false"
              open-direction="bottom"
              @search-change="getDataVehicle"
              @input="setDataCus"
              placeholder=""
              class="helo"
              default
              :class="{
                'is-invalid': submitform && $v.vin.$error,
              }"
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
            <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
              <span v-if="!$v.vin.required">Please Selected Data.</span>
            </div>
          </div>
        </div>
        <div class="col-6 col-sm-4 col-md-6">
          <div class="mb-3 position-relative">
            <code> * </code>
            <label>ชื่อลูกค้า</label>
            <multiselect
              v-model="cusName"
              label="nameTh"
              :options="rowsUser"
              :show-labels="false"
              :custom-label="customLabel"
              open-direction="bottom"
              @search-change="getDataCustomer"
              @remove="getRemoveSelected"
              placeholder=""
              :class="{
                'is-invalid': submitform && $v.cusName.$error,
              }"
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
            <div v-if="submitform && $v.cusName.$error" class="invalid-tooltip">
              <span v-if="!$v.cusName.required">Please Selected Data.</span>
            </div>
          </div>
        </div>

        <div class="col-6 col-sm-4 col-md-3">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">เลขตัวถัง</label>
            <multiselect
              v-model="vin"
              label="vin"
              :options="rowsDataService"
              :show-labels="false"
              :close-on-select="true"
              :internal-search="true"
              open-direction="bottom"
              @search-change="getDataVehicle"
              placeholder=""
              :class="{
                'is-invalid': submitform && $v.vin.$error,
              }"
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
            <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
              <span v-if="!$v.vin.required">Please Selected Data.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-sm-4 col-lg-3">
          <div class="mb-3 position-relative">
            <label for="validation"
              >ทะเบียน<code> ** กรณีไม่มีข่อมูลในระบบ</code></label
            >
            <input
              v-model="newVehicle"
              type="text"
              class="form-control"
              :maxlength="10"
            />
          </div>
        </div>

        <div class="col-6 col-sm-4 col-lg-6">
          <div class="mb-3 position-relative">
            <label for="validation"
              >ชื่อลูกค้า<code> ** กรณีไม่มีข่อมูลในระบบ</code></label
            >
            <input
              v-model="newCusName"
              type="text"
              class="form-control"
              :maxlength="10"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="mb-3 position-relative">
            <code>* </code>
            <label for="validationTooltiptype">ประเภทการเช็คระยะ</label><br />
            <multiselect
              v-model="distances"
              :options="rowDataSd"
              label="nameTh"
              :class="{
                'is-invalid': submitform && $v.distances.$error,
              }"
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
            <div
              v-if="submitform && $v.distances.$error"
              class="invalid-tooltip"
            >
              <span v-if="!$v.distances.required">Please Selected Data.</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-sm-3 col-md-3">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">สถานะ</label>
            <multiselect
              v-model="status"
              label="text"
              type="search"
              :options="optionsAppointment"
              :show-labels="false"
              open-direction="bottom"
              placeholder=""
              class="helo"
              default
              :class="{
                'is-invalid': submitform && $v.vin.$error,
              }"
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
            <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
              <span v-if="!$v.vin.required">Please Selected Data.</span>
            </div>
          </div>
        </div>

        <div class="col-6 col-sm-3 col-lg-3">
          <div class="mb-3 position-relative">
            <label for="validation">เบอร์โทรศัพท์</label>
            <input
              v-model="cusMobilePhone"
              type="text"
              class="form-control"
              :maxlength="10"
            />
          </div>
        </div>

        <div class="col-6 col-sm-3 col-md-3">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">วันที่เข้ารับบริการ</label>
            <date-picker
              v-model="apptDate"
              format="YYYY-MM-DD"
              value-type="format"
            ></date-picker>
          </div>
        </div>

        <div class="col-6 col-sm-2 col-lg-2">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">เวลาเสร็จสิ้น</label>
            <date-picker
              v-model="apptTime"
              format="HH:mm"
              value-type="format"
              type="time"
            >
            </date-picker>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-sm-6 col-md-6">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">ประเภทการนัดหมาย</label>
            <b-form-checkbox-group
              v-model="selectedType"
              :options="rowsApptype"
              class="mb-3"
              value-field="apptTypeId"
              text-field="nameTh"
              disabled-field="notEnabled"
            ></b-form-checkbox-group>
          </div>
        </div>
        <div class="col-6 col-sm-6 col-lg-6">
          <div class="mb-3 position-relative">
            <code>* </code>
            <label for="validation">หมายเหตุ</label>
            <b-form-textarea
              id="textarea"
              v-model="apptTypeRemark"
              placeholder="กรณีที่ต้องการพิมพ์เรื่องเข้ามา"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </div>
      </div>

      <hr />
      <div class="row">
        <div class="col-md-12 text-end">
          <!---->
          <b-button
            class="mt-2 btn btn-primary"
            @click="formContact = true"
            :disabled="formContactButtonDisabled"
            >เพิ่มข้อมูลการติดต่อ</b-button
          >
          &nbsp;
          <b-button class="mt-2 btn btn-success" @click="tooltipForm"
            >บันทึก</b-button
          >
        </div>
      </div>

      <hr />
      <div v-show="formContact">
        <h5>เพิ่มข้อมูลการติดต่อ (Form Contact)</h5>
        <div class="text-end">
          <button class="btn btm-success"></button>
        </div>
        <div class="row">
          <div class="col-6 col-sm-12 col-lg-3">
            <div class="mb-3 position-relative">
              <label for="validation">มือถือ</label>
              <input
                v-model="cusMobilePhone"
                type="text"
                class="form-control"
                :maxlength="10"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">ประเภทการติดต่อ</label>
              <multiselect
                v-model="contactType"
                label="contactTypeNameTh"
                type="search"
                :options="optionsContactType"
                open-direction="bottom"
                placeholder=""
                class="helo"
                default
                :class="{
                  'is-invalid': submitform && $v.vin.$error,
                }"
              >
                <span slot="noResult">ไม่พบข้อมูล</span>
              </multiselect>
              <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
                <span v-if="!$v.vin.required">Please Selected Data.</span>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">ผลการติดต่อ</label>
              <multiselect
                v-model="resultId"
                label="nameTh"
                type="search"
                :options="optionsResult"
                open-direction="bottom"
                placeholder=""
                class="helo"
                default
                :class="{
                  'is-invalid': submitform && $v.vin.$error,
                }"
              >
                <span slot="noResult">ไม่พบข้อมูล</span>
              </multiselect>
              <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
                <span v-if="!$v.vin.required">Please Selected Data.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5">
            <div class="mb-3 position-relative">
              <label for="validation">เรื่องที่ติดต่อ</label>
              <input v-model="subjectCT" type="text" class="form-control" />
            </div>
          </div>

          <div class="col-6 col-sm-6 col-md-6">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">รายละเอียดการติดต่อ</label>
              <b-form-textarea
                id="textarea"
                v-model="messageCT"
                placeholder="กรณีที่ต้องการพิมพ์เรื่องเข้ามา"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
        </div>

        <div class="col-md-11 text-end">
          <button class="btn btn-success" @click="postContact">เพิ่ม</button>
        </div>
      </div>
      <hr />
      <div class="row">
        <h5>ส่วนที่ 2 ประวัติการติดต่อ</h5>
      </div>
      <hr />
      <div class="row" v-if="rowShowContacts.length > 0">
        <div
          class="col-md-12"
          v-for="(rowCt, index) in rowShowContacts"
          :key="'Ct' + index"
        >
          <b> ผู้ติดต่อ : {{ rowCt.userName }}</b>
          <b> [ วันที่/เวลา : {{ rowCt.createdAt }} ] </b>

          <br />

          สถานะ : {{ rowCt.contactResultTh }}(เรื่องที่ติดต่อ :
          {{ rowCt.subject }} , {{ rowCt.message }})
        </div>
      </div>
      <div v-else>ไม่พบข้อมูล</div>
    </b-modal>

    <!-- Ends Modal Add Ap -->

    <!-- Modal Edit Ap -->
    <b-modal
      modal-class="modal-fullscreen"
      size="xl"
      ref="modalShowAp"
      hide-footer
      title="ข้อมูลการนัดหมาย"
      @hide="clearsubmitAp"
    >
      <div class="row">
        <div class="col-6 col-sm-4 col-md-3">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">ทะเบียนรถในระบบ</label>
            <multiselect
              v-model="vin"
              label="licensePlate"
              type="search"
              :options="rowsDataService"
              :show-labels="false"
              open-direction="bottom"
              @search-change="getDataVehicle"
              placeholder=""
              class="helo"
              default
              :class="{
                'is-invalid': submitform && $v.vin.$error,
              }"
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
            <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
              <span v-if="!$v.vin.required">Please Selected Data.</span>
            </div>
          </div>
        </div>

        <div class="col-6 col-sm-4 col-md-6">
          <div class="mb-3 position-relative">
            <code> * </code>
            <label>ชื่อลูกค้าในระบบ</label>
            <multiselect
              v-model="cusName"
              label="nameTh"
              :options="rowsUser"
              :show-labels="false"
              open-direction="bottom"
              :custom-label="customLabel"
              @search-change="getDataCustomer"
              @remove="getRemoveSelected"
              placeholder=""
              :class="{
                'is-invalid': submitform && $v.cusName.$error,
              }"
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
            <div v-if="submitform && $v.cusName.$error" class="invalid-tooltip">
              <span v-if="!$v.cusName.required">Please Selected Data.</span>
            </div>
          </div>
        </div>

        <div class="col-6 col-sm-4 col-md-3">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">เลขตัวถัง</label>
            <multiselect
              v-model="vin"
              label="vin"
              :options="rowsDataService"
              :show-labels="false"
              :close-on-select="true"
              :internal-search="true"
              open-direction="bottom"
              @search-change="getDataVehicle"
              placeholder=""
              :class="{
                'is-invalid': submitform && $v.vin.$error,
              }"
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
            <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
              <span v-if="!$v.vin.required">Please Selected Data.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-sm-4 col-lg-3">
          <div class="mb-3 position-relative">
            <label for="validation"
              >ทะเบียน<code> ** กรณีไม่มีข่อมูลในระบบ</code></label
            >
            <input
              v-model="newVehicle"
              type="text"
              class="form-control"
              :maxlength="10"
            />
          </div>
        </div>

        <div class="col-6 col-sm-4 col-lg-6">
          <div class="mb-3 position-relative">
            <label for="validation"
              >ชื่อลูกค้า<code> ** กรณีไม่มีข่อมูลในระบบ</code></label
            >
            <input
              v-model="newCusName"
              type="text"
              class="form-control"
              :maxlength="10"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="mb-3 position-relative">
            <code>* </code>
            <label for="validationTooltiptype">ประเภทการเช็คระยะ</label><br />
            <multiselect
              v-model="distances"
              :options="rowDataSd"
              label="nameTh"
              :class="{
                'is-invalid': submitform && $v.distances.$error,
              }"
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
            <div
              v-if="submitform && $v.distances.$error"
              class="invalid-tooltip"
            >
              <span v-if="!$v.distances.required">Please Selected Data.</span>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-sm-3 col-md-3">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">สถานะ</label>
            <multiselect
              v-model="status"
              label="text"
              type="search"
              :options="optionsAppointment"
              :show-labels="false"
              open-direction="bottom"
              class="helo"
              default
              :class="{
                'is-invalid': submitform && $v.vin.$error,
              }"
            >
              <span slot="noResult">ไม่พบข้อมูล</span>
            </multiselect>
            <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
              <span v-if="!$v.vin.required">Please Selected Data.</span>
            </div>
          </div>
        </div>

        <div class="col-6 col-sm-3 col-lg-3">
          <div class="mb-3 position-relative">
            <label for="validation">เบอร์โทรศัพท์</label>
            <input
              v-model="cusMobilePhone"
              type="text"
              class="form-control"
              :maxlength="10"
            />
          </div>
        </div>

        <div class="col-6 col-sm-3 col-md-3">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">วันที่เข้ารับบริการ</label>
            <date-picker
              v-model="apptDate"
              format="YYYY-MM-DD"
              value-type="format"
            ></date-picker>
          </div>
        </div>

        <div class="col-6 col-sm-2 col-lg-2">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">เวลาเสร็จสิ้น</label>
            <date-picker
              v-model="apptTime"
              format="HH:mm"
              value-type="format"
              type="time"
            >
            </date-picker>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-sm-6 col-md-6">
          <div class="mb-3 position-relative">
            <code>* </code><label for="validation">ประเภทการนัดหมาย</label>
            <b-form-checkbox-group
              v-model="selectedType"
              :options="rowsApptype"
              class="mb-3"
              value-field="apptTypeId"
              text-field="nameTh"
              disabled-field="notEnabled"
            ></b-form-checkbox-group>
          </div>
        </div>

        <div class="col-6 col-sm-6 col-lg-6">
          <div class="mb-3 position-relative">
            <code>* </code>
            <label for="validation">หมายเหตุ</label>
            <b-form-textarea
              id="textarea"
              v-model="apptTypeRemark"
              placeholder="กรณีที่ต้องการพิมพ์เรื่องเข้ามา"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-6 col-sm-3 col-md-2">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">ช่องจอด</label>
              <multiselect
                v-model="pslotId"
                label="nameTh"
                :options="rowsPslot"
                :show-labels="false"
                open-direction="bottom"
                placeholder=""
                class="helo"
                default
              >
                <span slot="noResult">ไม่พบข้อมูล</span>
              </multiselect>
            </div>
          </div>
          <div class="col-6 col-sm-3 col-md-2">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">Slot</label>
              <multiselect
                v-model="fslotId"
                label="nameTh"
                :options="rowsFslot"
                :show-labels="false"
                open-direction="bottom"
                placeholder=""
                class="helo"
                default
              >
                <span slot="noResult">ไม่พบข้อมูล</span>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-12 text-end">
          <b-button class="mt-2 btn btn-primary" @click="formContact = true"
            >เพิ่มข้อมูลการติดต่อ</b-button
          >
          &nbsp;
          <b-button
            class="mt-2 btn btn-success"
            @click="submitUpdateAppointment"
            >บันทึก</b-button
          >
        </div>
      </div>
      <hr />

      <div v-show="formContact">
        <h5>เพิ่มข้อมูลการติดต่อ (Form Contact)</h5>
        <div class="text-end">
          <button class="btn btm-success"></button>
        </div>
        <div class="row">
          <div class="col-6 col-sm-12 col-lg-3">
            <div class="mb-3 position-relative">
              <label for="validation">มือถือ</label>
              <input
                v-model="cusMobilePhone"
                type="text"
                class="form-control"
                :maxlength="10"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">ประเภทการติดต่อ</label>
              <multiselect
                v-model="contactType"
                label="contactTypeNameTh"
                type="search"
                :options="optionsContactType"
                open-direction="bottom"
                placeholder=""
                class="helo"
                default
                :class="{
                  'is-invalid': submitform && $v.vin.$error,
                }"
              >
                <span slot="noResult">ไม่พบข้อมูล</span>
              </multiselect>
              <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
                <span v-if="!$v.vin.required">Please Selected Data.</span>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">ผลการติดต่อ</label>
              <multiselect
                v-model="resultId"
                label="nameTh"
                type="search"
                :options="optionsResult"
                open-direction="bottom"
                placeholder=""
                class="helo"
                default
                :class="{
                  'is-invalid': submitform && $v.vin.$error,
                }"
              >
                <span slot="noResult">ไม่พบข้อมูล</span>
              </multiselect>
              <div v-if="submitform && $v.vin.$error" class="invalid-tooltip">
                <span v-if="!$v.vin.required">Please Selected Data.</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-5">
            <div class="mb-3 position-relative">
              <label for="validation">เรื่องที่ติดต่อ</label>
              <input v-model="subjectCT" type="text" class="form-control" />
            </div>
          </div>

          <div class="col-6 col-sm-6 col-md-6">
            <div class="mb-3 position-relative">
              <code>* </code><label for="validation">รายละเอียดการติดต่อ</label>
              <b-form-textarea
                id="textarea"
                v-model="messageCT"
                placeholder="กรณีที่ต้องการพิมพ์เรื่องเข้ามา"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </div>
          </div>
        </div>

        <div class="col-md-11 text-end">
          <button class="btn btn-success" @click="postContact">เพิ่ม</button>
        </div>
      </div>
      <hr />

      <div class="row">
        <h5>ส่วนที่ 2 ประวัติการติดต่อ</h5>
      </div>
      <hr />
      <div class="row" v-if="rowShowContacts.length > 0">
        <div
          class="col-md-12"
          v-for="(rowCt, index) in rowShowContacts"
          :key="'Ct' + index"
        >
          <b> ผู้ติดต่อ : {{ rowCt.userName }}</b>
          <b> [ วันที่/เวลา : {{ rowCt.createdAt }} ] </b>

          <br />

          สถานะ : {{ rowCt.contactResultTh }}(เรื่องที่ติดต่อ :
          {{ rowCt.subject }} , {{ rowCt.message }})
        </div>
      </div>
      <div v-else>ไม่พบข้อมูล</div>
    </b-modal>

    <!-- Ends Modal EDit &  Show Ap -->

    <!-- start EditStatus Vm -->
    <b-modal
      ref="modalUpdateFslot"
      hide-footer
      title="อัพเดทสถานะการซ่อม"
      @hide="clearsubmitAp"
    >
      <center>
        <h2>หมายเลขทะเบียน : {{ itemVMP.licensePlate }}</h2>
        <h2>
          สถานะ :
          <multiselect
            v-model="repairStatus"
            :options="optionsStatusFslot"
            label="nameTh"
          >
          </multiselect>
        </h2>
      </center>
      <hr />
      <button class="btn btn-success" @click="putStatusVm()">อัพเดท</button>
    </b-modal>
    <!-- Ends EditStatus Vm modal -->
  </Layout>
</template>
